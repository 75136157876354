import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout.js"
import Image from "../components/image/image"
import SEO from "../components/seo/seo"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Lorem Ipsum</h1>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    {/* <Link to="/projects/">Go to projects</Link>
    <Link to="/contact/">Go to contact</Link> */}
  </Layout>
)

export default IndexPage
